import IndexPage from 'pages';
import IRoute from '../interfaces/route';

const routes: IRoute[] = [
    {
        path: '/:id',
        name: 'Index',
        component: IndexPage,
    },
]

export default routes;


