// @ts-ignore */
import React from "react";
import ReactDOM from "react-dom/client";
import Application from "./application";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import "@vime/core/themes/default.css";
import "./assets/css/main.scss";
import "./assets/css/custom.scss";
import "./assets/css/global.scss";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import { PersistGate } from "redux-persist/integration/react";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Application />
    </PersistGate>
  </Provider>
);
