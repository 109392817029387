import axios from "util/axios";
import config from "config/config";

export async function currentUser() {
  return axios.get(`${config.defaults.api_url}/users/me`);
}

export async function getVideos(id: any) {
  return axios.get<{
    message: string;
    practiceInfo: {
      Id: number;
      Status: {
        Id: number;
        Name: string;
      };
      ChannelKey: number;
      Name: string;
      Country: string;
    };
    results: {
      url: string;
      type: string;
      isExist: false;
      sortOrder: number;
    }[];
  }>(`${config.defaults.api_url}/videos/streaming/${id}`);
}
